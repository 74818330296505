.card-item-icon, .card-item-icon2 {
  font-size: 50px;
  margin: 0px;
  transition: 1.5s;
  color: #4169e1;
}

.card-item, .card-item-link{
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.5s;
} 

.card-item-home{
  background-color: white;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.5s;
} 

.card-item-home P,
.card-item-home H2{
  color: black;

} 

.card-item-home .card-item-icon {
  color: #4169e1;
}



.left-card-item, .left-card-item-link{
  text-align: left;
} 

.card-item:hover, .card-item-home:hover {
  box-shadow: 0 10px 18px 0 rgba(0,0,0,0.2);
}

.card-item-link:hover .card-item-icon,
.card-item:hover .card-item-icon,
.card-item-home:hover .card-item-icon
{
  transform: scaleX(-1);
}

.card-item:hover .card-item-icon2
{
  transform: scale(1.5,1.5);
}


.card-item-link:hover {
  background-color: #4169e1;  
}



.card-item-title {
  /* background: red; */
  font-size: 1.5rem;
  /* color:rgba(0, 0, 0, 0.8) */
}

.card-item-description {
  /* background: green; */
  font-size: 1rem;
}

.card-item-description2 P{
  /* background: green; */
  margin:0px;
}

.card-item-table {
  margin-left: auto;
  margin-right: auto;
}

td {
  padding-left: 10px;
  padding-right: 10px;
  /* text-align: left; */
}

tr:nth-child(even) {
  background-color: #31313117;
}
tr:nth-child(odd) {
  background-color: #d3d3d3;
}


li {
  text-align: left;
}