.mainImageContainer {
  position: relative;
}

.mainImage { 
  width: 100%;
  height: auto;
  min-height: 300px;
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}

@font-face {
    font-family: Calibri;
    src: url(../static/calibri.ttf);
    }


.mmcylogo { 
  height: 200px;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  padding: 10px;
  color:white;
  font-size: 2rem;
}

.ContainerWhite {
  width: 100%;
  height: auto;
  background-color: white;
}
.ContainerGrey {
  width: 100%;
  height: auto;
  background-color: rgb(231, 231, 231);
}

.clientsLogo {
  max-height: 60px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 50px;
}

.servicesContainer {
  margin-bottom: 50px;
}

H2 {
  font-size: 2rem;
}

.servicesCardLink {
  text-decoration-line: underline;
  text-decoration-color: #4169e1;
  text-decoration-thickness: 5px;
}


.servicesCard {
  /* background-color: rgba(141, 141, 141, 0.8); */
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  padding: 20px;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: Left;
  display: flex;
  
}

/* On mouse-over, add a deeper shadow */
.servicesCard:hover {
  box-shadow: 0 15px 29px 0 rgba(0,0,0,0.2);
}

.servicesImage {
  width: auto;
  max-height: 300px;
}

.servicesItemLeft {
  /* background-color: #00ff0096; */
  padding: 10px;
  flex: 50%;
  order: 1;
}

.servicesItemRight {
  /* background-color: rgba(255, 30, 30, 0.644); */
  padding: 10px;
  flex: 50%;
  order: 2;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 900px) {
  .servicesCard {
    flex-direction: column;
    text-align: center;
  }
  .servicesItemLeft {
    order: 0;
  }
  .servicesItemRight {
    order: 0;
  }
  .servicesImage {
    max-height: 250px;
  }
  .mmcylogo { 
    height: 150px;
  }
}


@media (max-width: 500px) {
  .servicesImage {
    max-height: 200px;
  }
  .mmcylogo { 
    max-height: 1%;
  }
}

@media (max-width: 300px) {
  .servicesImage {
    max-height: 150px;
  }
  .clientsLogo {
    max-width: 80%;
  }
}



