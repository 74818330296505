@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');
* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  background-color: #ffffff;
  margin: 0px;
  text-align: center;
}

.highlight  {
  color: #4169e1;
  font-size: 2.4rem;
}

.ContainerGrey {
  width: 100%;
  height: auto;
  background-color: rgb(231, 231, 231);
}
.sectionA {
  /* min-height: 100vh; */
  margin-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.sectionA #clients{
  /* min-height: 100vh; */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.sectionB {
  /* margin-top: 50px; */
  max-width: 1000px; /*For larger screens */
  width: 95%; /*For small screens */
  /* background-color: rgba(141, 141, 141, 0.8); */
  /* border-radius: 5px; */
}

.sectionC {
  margin-top: 25px;
  max-width: 1000px; /*For larger screens */
  width: 95%; /*For small screens */
  /* background-color: rgba(160, 160, 160, 0.8); */
  border-radius: 5px;
  color: black;
}

.testimony {
  background-color: rgb(231, 231, 231);
  border-radius: 30px;
  padding: 5px;
  margin: 5px
}

h1 {
  margin-top: 30px;
  font-size: 2rem;
}

a {
  color: white;
  text-decoration: none;
}

p {
  font-size: 1rem;
  padding: 1%;
}

.p1 {
  text-align: left;
  padding-left: 10px;
}


.ContainerColumnsTwo, 
.ContainerColumnsThree, 
.ContainerColumnsFour,
.ContainerColumnsCustomerSupport {
  /* color: white; */
  display: inline-grid;
  grid-column-gap: 14px;
  grid-row-gap: 40px;
  padding: 10px;
}

.ContainerColumnsOne {
  grid-template-columns: 80%;  
}

.ContainerColumnsTwo {
  grid-template-columns: 50% 50%;  
}

.ContainerColumnsThree, .ContainerColumnsCustomerSupport {
  grid-template-columns: 32% 32% 32%;
}

.ContainerColumnsFour {
  grid-template-columns: 24% 24% 24% 24%;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: black;
  /* font-weight: bold; */
  /* margin-bottom: 0.05rem; */
}

.redtext {color:red;}

.control input, 
.control select, 
.control textarea  {
  font: inherit;
  background-color: white;
  color: #17375E;
  border-radius: 4px;
  border: 1px solid rgb(184, 184, 184);
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #4169e1;
  border: 1px solid #4169e1;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #2355E1;
  border-color: #2355E1;
}

.actions button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background-color: #494b50d7;
  border: 1px solid #494b50d7;
} 

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #4169e1;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #7A777A;
}

.actions a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.actions a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.actions a:hover {
  color: #2355E1;
  background-color: transparent;
  text-decoration: underline;
}

.actions a:active {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

/* Remove arrows from number fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.center {
  text-align: center;
  margin: auto;
}

button {
  font-size: 1rem ;
  padding: 15px 15px;
  border: none;
  margin: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.red {
  background-color: #d9534f;
  color: white
}
.red:hover {
  background-color: #c9302c;
  cursor: pointer;
}
.red:disabled {
  background-color: #acacac;
  color: #5f5f5f;
  cursor: not-allowed;
}

.orange {
  background-color: #f0ad4e;
  color: white
}
.orange:hover {
  background-color: #ec971f;
  cursor: pointer;
}
.orange:disabled {
  background-color: #acacac;
  color: #5f5f5f;
  cursor: not-allowed;
}


.green {
  background-color: #5cb85c;
  color: white
}
.green:hover {
  background-color: #449d44;
}

.blue {
  background-color: #5bc0de;
  color: white
}
.blue:hover {
  background-color: #31b0d5;
}

/* Support Pages */
table {
  margin-left: auto;
  margin-right: auto;
}

/* Contact Us Section */
.whatsapp {
  max-width: 200px;
}

.message{
  grid-column: 1 / span 1;
}

.contactusContainer {
  display: inline-grid;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
  grid-template-columns: 45% 45%;
  padding: 1px;
  text-align: left;
}

.registerContainer {
  grid-row-gap: 10px;
  grid-column-gap: 50px;
  grid-template-columns: 80%;
  padding-left: 20%;
  padding-right: 20%;
  /* padding: 1px; */
  text-align: left;
}

textarea {
  resize: none;
}

.thankyouImage { 
  padding-top: 20%;
  width: 100%;
  max-width: 600px;
  height: auto;
  /* min-height: 300px; */
  background-size: cover;
  background-position: center;
}

.lifecyclImage { 
  /* padding-top: 20%; */
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 400px;
  /* min-height: 300px; */
  margin:auto;

}

.recaptcha {
  margin: auto;
  width: 50%;
}

@media screen and (max-width: 1050px) {
  .ContainerColumnsCustomerSupport {
    grid-template-columns: 46% 46%;
  }
}

@media screen and (max-width: 900px) {
  .ContainerColumnsFour {
    grid-template-columns: 46% 46%;
  }
  .ContainerColumnsThree {
    grid-template-columns: 46% 46%;
  }
}

@media screen and (max-width: 700px) {
  .contactusContainer {
    grid-template-columns: 100%;
    width: 95%;
  }
  .message{
    grid-column: 1 / span 1;
  }
  .recaptcha {
    margin: 1px;
  }
}

@media screen and (max-width: 600px) {
  .ContainerColumnsTwo {
    grid-template-columns: 100%;
  }
  .ContainerColumnsThree {
    grid-template-columns: 100%;
  }
  .ContainerColumnsCustomerSupport {
    grid-template-columns: 100%;
  }
  .ContainerColumnsFour {
    grid-template-columns: 100%;
  }
  .registerContainer {
    padding-left: 2%;
    padding-right: 2%;
  } 
}

.CogIcon {
  height: 6vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .CogIcon {
    animation: Cog-icon-spin infinite 10s linear;
  }
}


@keyframes Cog-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}