/* The container */
.checkboxcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 50px;
  }
  
  /* Hide the browser's default checkbox */
  .checkboxcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkboxcheckmark, .radioselect {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color:white;
    border: 1px solid rgb(184, 184, 184);
  }

  .radioselect {
    border-radius: 50%;
  }
  
  
  /* On mouse-over, add a grey background color */
  .checkboxcontainer:hover input ~ .checkboxcheckmark,
  .checkboxcontainer:hover input ~ .radioselect  {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkboxcontainer input:checked ~ .checkboxcheckmark,
  .checkboxcontainer:hover input ~ .radioselect  {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkboxcheckmark:after,
  .radioselect:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkboxcontainer input:checked ~ .checkboxcheckmark:after,
  .checkboxcontainer input:checked ~ .radioselect:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkboxcontainer .checkboxcheckmark:after, 
  .checkboxcontainer .radioselect:after{
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkboxcontainer .radioselect:after{
    border-radius: 50%;
  }
  .error {
    margin-top: 0.25rem;
    color: #d8182c;
  }
  
  /* ... */
  
  .formerror {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.5rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 1rem 0;
  }
  
