 /* Add a black background color to the top navigation */
 .topnav {
  background-color: #17375E;
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0;
  width: 100%;
  z-index: 1;
}

.mmcynavlogo { 
  height: 50px;
}

a { height: 50px;}

/* Style the links inside the navigation bar */
.mmcynavlinksdiv a {
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1rem;
}
/* Change the color of links on hover */
.topnav .navLinks a:hover {
  background-color: #ddd;
  color: black;
}
/* Add an active class to highlight the current page */
.topnav a.active {
  color: white;
}
.navLinks a{
  float: left;
  display: none;
}

.mmcynavlinksdiv{
  position: absolute;
  right: 0;
}

/* Show menu link on mobiles first */
.topnav .hamMenu {
  /* display: block; */
  position: relative;
  display: block;
  padding: 9px;
  cursor: pointer;
  width: 50px;
  /* margin: 10px auto; */
}

/* Hamburg menu */
.bar1, .bar2, .bar3 {
  background-color: white;
  width: 35px;
  height: 3px;
  margin: 6px 0;
  transition: 0.4s;
}
/* Hamburg menu - animation */
.topnavresponsive .bar1 {
  transform: translate(0, 9px) rotate(-405deg);
}
.topnavresponsive .bar2 {opacity: 0;}
.topnavresponsive .bar3 {
  transform: translate(0, -9px) rotate(405deg);
}

/* User menu always displayed regardless of device type */
.topnav a.userMenu {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.mmcynavbuttons {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #4169e1;
  border: 1px solid #4169e1;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 7px 8px;
}

button:hover {
  background-color: #2355E1;
  border-color: #2355E1;
}

/* On larger screens hide the hamburg menu */
@media screen and (min-width: 800px) {
  .topnav a {display: block;}
  .topnav a.hamMenu {
    display: none;
  }
  .bar1, .bar2, .bar3  {
    display: none;
    margin: 0;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the hamMenu. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */

@media screen and (max-width: 800px) {
  .topnavresponsive {
    position: relative;
  }
  .mmcynavlinksdiv{
    position: relative;
  }
  
  .topnavresponsive a.hamMenu {
    width: 50px;
  }
  .topnavresponsive a {
    float: none;
    display: block;
    text-align: left;
    z-index: 100;
  }
}

footer {
  background-color: #333;
  padding-top: 1%;
  padding-bottom: 1%;
  /* margin-top: 5%; */
  color: white;
}

footer P{
  padding: 1%;
  margin: 0%;
  color: white;
}
